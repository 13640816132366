import { configureStore } from "@reduxjs/toolkit";
import signInReducer from "./feautures/auth/signInSlice";
import verify2FAReducer from "./feautures/auth/verify2FASlice";
import validateFAReducer from "./feautures/auth/validate2FASlice";
import addMerchantRateReducer from "./feautures/manageRates/addMerchantRateSlice";
import defaultRateReducer from "./feautures/manageRates/getDefaultRatesSlice";
import updateDefaultRateReducer from "./feautures/manageRates/updateDefaultRateSlice";
import updateMerchantRateReducer from "./feautures/manageRates/updateMerchantRateSlice";
import merchantRateReducer from "./feautures/manageRates/getMerchantRatesSlice";
import getMerchantInformationReducer from "./feautures/manageRates/getMerchantInformationSlice";
import isUsing2FAReducer from "./feautures/global/isUsing2FA";
import getTopTenTransactionReducer from "./feautures/finance/getTopTenTransactionSlice";
import getTransactionLogReducer from "./feautures/transactionLogSlice/getTransactionLogSlice";
import customerListReducer from "./feautures/customer/getCustomerListSlice";
import customerInformationReducer from "./feautures/customer/getCustomerInformationSlice";
import getTransactionReducer from "./feautures/transactionLogSlice/getTransactionSlice";
import allTransactionLogReducer from "./feautures/transactionLogSlice/getAllTransactionLogSlice";
import getMerchantTransactionLogReducer from "./feautures/transactionLogSlice/getMerchantTransactionLogSlice";
import updateTransactionStatusReducer from "./feautures/transactionLogSlice/updateTransactionStatusSlice";
import generalFinanceReportReducer from "./feautures/financeReport/getGeneralFinanceReportSlice";
import merchantFinanceReportReducer from "./feautures/financeReport/getMerchantFinanceReportSlice";
import accountBalanceReducer from "./feautures/finance/getAccountBalanceSlice";
import transactionCountReducer from "./feautures/finance/getTransactionCountSlice";
import transactionValueReducer from "./feautures/finance/getTransactionValueSlice";
import transactionLogDownloadReducer from "./feautures/transactionLogSlice/getAllTransactionLogDownloadSlice";
import merchantTransactionLogDownloadReducer from "./feautures/transactionLogSlice/getMerchantTransactionLogDownloadSlice";
import getTransactionLogToEmailReducer from "./feautures/transactionLogSlice/getTransactionLogToEmailSlice";
import getMerchantTransactionToEmailReducer from "./feautures/transactionLogSlice/getMerchantTransactionToEmailSlice";
import dashboardOverviewReducer from "./feautures/dashboard/getDashboardOverviewSlice";
import allAccountReducer from "./feautures/user/getAllAccountSlice";
import merchAntanalysticsReducer from "./feautures/user/getMerchantAnalysticsSlice";
import paymentanalysticsReducer from "./feautures/finance/getPaymentAnalysticsSlice";

export const store = configureStore({
  reducer: {
    signIn: signInReducer,
    addMerchantRate: addMerchantRateReducer,
    defaultRate: defaultRateReducer,
    updateDefaultRate: updateDefaultRateReducer,
    updateMerchantRate: updateMerchantRateReducer,
    merchantRate: merchantRateReducer,
    getMerchantInformation: getMerchantInformationReducer,
    verify2FA: verify2FAReducer,
    validateFA: validateFAReducer,
    isUsing2FA: isUsing2FAReducer,
    getTopTenTransaction: getTopTenTransactionReducer,
    getTransactionLog: getTransactionLogReducer,
    allTransactionLog: allTransactionLogReducer,
    customerInformation: customerInformationReducer,
    customerList: customerListReducer,
    getTransaction: getTransactionReducer,
    getMerchantTransactionLog: getMerchantTransactionLogReducer,
    updateTransactionStatus: updateTransactionStatusReducer,
    generalFinanceReport: generalFinanceReportReducer,
    merchantFinanceReport: merchantFinanceReportReducer,
    accountBalance: accountBalanceReducer,
    transactionCount: transactionCountReducer,
    transactionValue: transactionValueReducer,
    transactionLogDownload: transactionLogDownloadReducer,
    merchantTransactionLogDownload: merchantTransactionLogDownloadReducer,
    getTransactionLogToEmail: getTransactionLogToEmailReducer,
    getMerchantTransactionToEmail: getMerchantTransactionToEmailReducer,
    dashboardOverview: dashboardOverviewReducer,
    allAccount: allAccountReducer,
    merchAntanalystics: merchAntanalysticsReducer,
    paymentanalystics: paymentanalysticsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
