import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  CardMedia,
  Divider,
  Button,
  CircularProgress,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { Link } from "react-router-dom";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ClearIcon from "@mui/icons-material/Clear";
import { ROUTES } from "../../../constants/RouteData";
import { GetAllAccount } from "../../../../state/feautures/user/getAllAccountSlice";
import { useAppDispatch } from "../../../../state/hooks";
import { GetMerchantList } from "../../../../api/Data";
const FilterIcon = `${process.env.PUBLIC_URL}/assets/images/filter.svg`;

const MerchantOverviewTable = ({ data }: any) => {
  const [searchQuery, setSearchQuery] = useState<any>("");
  const [clearField, setClearField] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [merchants, setMerchants] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const itemsPerPage = data?.numberOfElements;
  const TotalPages = data?.totalPages;

  useEffect(() => {
    setMerchants(data);
  }, [data]);

  const goToPreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
      //   dispatchDataFetch(currentPage);
    }
  };

  const goToNextPage = () => {
    setCurrentPage(currentPage + 1);
    // dispatchDataFetch(currentPage);
  };
  return (
    <Box sx={{ boxShadow: 1, marginTop: "30px" }}>
      <Box
        sx={{
          padding: "15px",
          display: "flex",
          gap: "1rem",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: { xs: "100%", md: "400px" },
          }}
        >
          {/* Search Bar */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "10px",
              marginLeft: "auto",
              width: "300px",
              // padding: "3px 0px 3px 2px", bgdgsgj
              position: "relative",
            }}
          >
            {" "}
            <FormControl fullWidth>
              <OutlinedInput
                id="outlined-adornment-amount"
                value={searchQuery}
                // onChange={handleSearchChange}
                // onKeyDown={handleKeyPress} // Add this line to handle key press
                startAdornment={
                  <InputAdornment position="start">
                    <SearchRoundedIcon
                      // onClick={handleSearch}
                      sx={{
                        cursor: "pointer",
                      }}
                    />
                  </InputAdornment>
                }
                sx={{ height: "40px" }}
              />
            </FormControl>
            {clearField ? (
              <ClearIcon
                // onClick={clearSearchField}
                sx={{
                  cursor: "pointer",
                  position: "absolute",
                  left: "248px",
                }}
              />
            ) : (
              ""
            )}
          </Box>
          {/* Search Bar */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              width: "110px",
              height: "40px",
              border: "1px solid black",
              borderRadius: "6px",
              padding: "1px 4px",
              cursor: "pointer",
            }}
          >
            {" "}
            <CardMedia
              component="img"
              height="20px"
              image={FilterIcon}
              alt="Icon"
              sx={{
                objectFit: "contain",
                width: "20px",
              }}
            />{" "}
            <Typography>Filter</Typography>{" "}
          </Box>
        </Box>
      </Box>
      <Divider />
      <TableContainer
        component={Paper}
        sx={{
          width: "100%",
          overflowX: "auto",
          borderRadius: "0",
          border: "none",
          boxShadow: 0,
        }}
      >
        <Table sx={{ padding: 0, margin: 0 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#FCDFAE1A", textTransform: "" }}>
              <TableCell sx={{ fontWeight: 600, fontSize: "14px" }}>
                Merchant ID
              </TableCell>
              <TableCell sx={{ fontWeight: 600, fontSize: "14px" }}>
                Account Information
              </TableCell>
              <TableCell
                align="center"
                sx={{ fontWeight: 600, fontSize: "14px" }}
              >
                Type
              </TableCell>{" "}
              <TableCell
                align="center"
                sx={{ fontWeight: 600, fontSize: "14px" }}
              >
                Account Status
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontWeight: 600, fontSize: "14px" }}
              >
                Country
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontWeight: 600, fontSize: "14px" }}
              >
                User Profile
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontWeight: 600, fontSize: "14px" }}
              >
                KYC Status
              </TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <TableBody>
              <CircularProgress />
            </TableBody>
          ) : (
            <>
              {" "}
              {merchants === undefined || merchants.length === 0 ? (
                <TableBody>
                  <Typography>NO DATA FOUND</Typography>
                </TableBody>
              ) : (
                <TableBody>
                  {merchants.map((data: any) => (
                    <TableRow
                      key={data.merchantId}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {data.merchantId === null ? "N/A" : data.merchantId}
                      </TableCell>
                      {/* <TableCell>{row.name}{row.email}</TableCell> */}
                      <TableCell
                        align="left"
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        <Box>
                          {data.companyName === null ? "N/A" : data.companyName}
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          <Box>{data.email === null ? "N/A" : data.email}</Box>{" "}
                          {data.verifiedEmail === "INACTIVE" ? (
                            <Box
                              sx={{
                                width: "10px",
                                height: "10px",
                                background: "red",
                                borderRadius: "100%",
                              }}
                            >
                              {" "}
                            </Box>
                          ) : data.verifiedEmail === "ACTIVE" ? (
                            <Box
                              sx={{
                                width: "10px",
                                height: "10px",
                                background: "green",
                                borderRadius: "100%",
                              }}
                            >
                              {" "}
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                width: "10px",
                                height: "10px",
                                background: "grey",
                                borderRadius: "100%",
                              }}
                            > 
                            </Box>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        {data.typeOfBusiness === "INDIVIDUAL BUSINESS" ? (
                          <Box
                            sx={{
                              backgroundColor: "#F59E0B",
                              padding: "3px 10px",
                              border: "1px solid #F59E0B",
                              borderRadius: "10px",
                              color: "#FFEEE5",
                              textAlign: "center",
                            }}
                          >
                            IND
                          </Box>
                        ) : data.typeOfBusiness === null ? (
                          <Box
                            sx={{
                              backgroundColor: "lighgrey",
                              padding: "3px 10px",
                              border: "1px solid grey",
                              borderRadius: "10px",
                              color: "grey",
                              textAlign: "center",
                            }}
                          >
                            N/A
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              backgroundColor: "#FFEEE5",
                              padding: "3px 10px",
                              border: "1px solid #F59E0B",
                              borderRadius: "10px",
                              color: "#F59E0B",
                              textAlign: "center",
                            }}
                          >
                            REG
                          </Box>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "left",
                            width: "100px",
                          }}
                        >
                          {data.state === "INACTIVE" ? (
                            <Box
                              sx={{
                                fontSize: "11px",
                                borderRadius: "10px",
                                padding: "2px 10px",
                                backgroundColor: "#F2F0F9",
                                display: "flex",
                                alignItems: "center",
                                // justifyContent: "space-between",
                                width: "100px",
                                marginBottom: "2px",
                                color: "#6E6893",
                              }}
                            >
                              <Box
                                sx={{
                                  height: "7px",
                                  width: "7px",
                                  borderRadius: "50%",
                                  backgroundColor: "#6E6893",
                                  marginRight: "5px",
                                }}
                              ></Box>
                              {data.state}
                            </Box>
                          ) : data.state === "REJECTED" ? (
                            <Box
                              sx={{
                                fontSize: "11px",
                                borderRadius: "10px",
                                padding: "2px 10px",
                                backgroundColor: "#F2F0F9",
                                display: "flex",
                                alignItems: "center",
                                // justifyContent: "space-between",
                                width: "100px",
                                marginBottom: "2px",
                                color: "red",
                              }}
                            >
                              <Box
                                sx={{
                                  height: "7px",
                                  width: "7px",
                                  borderRadius: "50%",
                                  backgroundColor: "red",
                                  marginRight: "5px",
                                }}
                              ></Box>
                              {data.state}
                            </Box>
                          ) : data.state === "SUSPENDED" ? (
                            <Box
                              sx={{
                                fontSize: "11px",
                                borderRadius: "10px",
                                padding: "2px 10px",
                                backgroundColor: "#F2F0F9",
                                display: "flex",
                                alignItems: "center",
                                // justifyContent: "space-between",
                                width: "100px",
                                marginBottom: "2px",
                                color: "red",
                              }}
                            >
                              <Box
                                sx={{
                                  height: "7px",
                                  width: "7px",
                                  borderRadius: "50%",
                                  backgroundColor: "red",
                                  marginRight: "5px",
                                }}
                              ></Box>
                              {data.state}
                            </Box>
                          ) : data.state === null ? (
                            <Box
                              sx={{
                                fontSize: "11px",
                                borderRadius: "10px",
                                padding: "2px 10px",
                                backgroundColor: "lightgray",
                                display: "flex",
                                alignItems: "center",
                                // justifyContent: "center",
                                width: "100px",
                                marginBottom: "2px",
                                color: "grey",
                              }}
                            >
                              <Box
                                sx={{
                                  height: "8px",
                                  width: "8px",
                                  borderRadius: "50%",
                                  backgroundColor: "grey",
                                  marginRight: "5px",
                                }}
                              ></Box>
                              N/A
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                fontSize: "11px",
                                borderRadius: "10px",
                                padding: "2px 10px",
                                backgroundColor: "#D8FFD8",
                                display: "flex",
                                alignItems: "center",
                                // justifyContent: "center",
                                width: "100px",
                                marginBottom: "2px",
                                color: "#07751A",
                              }}
                            >
                              <Box
                                sx={{
                                  height: "8px",
                                  width: "8px",
                                  borderRadius: "50%",
                                  backgroundColor: "#07751A",
                                  marginRight: "5px",
                                }}
                              ></Box>
                              {data.state}
                            </Box>
                          )}
                          <Box sx={{ fontSize: "12px" }}>
                            <b>Date:</b>{" "}
                            {data.registrationDate === null
                              ? "N/A"
                              : data.registrationDate.split("T")[0]}
                          </Box>
                        </Box>
                      </TableCell>

                      <TableCell align="center">
                        {data.country === null ? "N/A" : data.country}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        <Box>
                          {data.userProfile === null ? (
                            "N/A"
                          ) : (
                            <b>
                              {data.firstName} {data.lastName}
                            </b>
                          )}
                        </Box>
                        <Box>
                          {data.phone === null ? "N/A" : data.phone}
                        </Box>
                        {/* <Box>{data.userProfile}</Box> */}
                      </TableCell>
                      <TableCell align="left">
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "left",
                            width: "100px",
                          }}
                        >
                          {data.kyc === "INACTIVE" ? (
                            <Box
                              sx={{
                                fontSize: "11px",
                                borderRadius: "10px",
                                padding: "2px 10px",
                                backgroundColor: "#F2F0F9",
                                display: "flex",
                                alignItems: "center",
                                // justifyContent: "space-between",
                                width: "100px",
                                marginBottom: "2px",
                                color: "#6E6893",
                              }}
                            >
                              <Box
                                sx={{
                                  height: "7px",
                                  width: "7px",
                                  borderRadius: "50%",
                                  backgroundColor: "#6E6893",
                                  marginRight: "5px",
                                }}
                              ></Box>
                              {data.kyc}
                            </Box>
                          ) : data.kyc === "REJECTED" ? (
                            <Box
                              sx={{
                                fontSize: "11px",
                                borderRadius: "10px",
                                padding: "2px 10px",
                                backgroundColor: "#F2F0F9",
                                display: "flex",
                                alignItems: "center",
                                // justifyContent: "space-between",
                                width: "100px",
                                marginBottom: "2px",
                                color: "red",
                              }}
                            >
                              <Box
                                sx={{
                                  height: "7px",
                                  width: "7px",
                                  borderRadius: "50%",
                                  backgroundColor: "red",
                                  marginRight: "5px",
                                }}
                              ></Box>
                              {data.state}
                            </Box>
                          ) : data.state === "SUSPENDED" ? (
                            <Box
                              sx={{
                                fontSize: "11px",
                                borderRadius: "10px",
                                padding: "2px 10px",
                                backgroundColor: "#F2F0F9",
                                display: "flex",
                                alignItems: "center",
                                // justifyContent: "space-between",
                                width: "100px",
                                marginBottom: "2px",
                                color: "red",
                              }}
                            >
                              <Box
                                sx={{
                                  height: "7px",
                                  width: "7px",
                                  borderRadius: "50%",
                                  backgroundColor: "red",
                                  marginRight: "5px",
                                }}
                              ></Box>
                              {data.state}
                            </Box>
                          ) : data.state === null ? (
                            <Box
                              sx={{
                                fontSize: "11px",
                                borderRadius: "10px",
                                padding: "2px 10px",
                                backgroundColor: "lightgray",
                                display: "flex",
                                alignItems: "center",
                                // justifyContent: "center",
                                width: "100px",
                                marginBottom: "2px",
                                color: "grey",
                              }}
                            >
                              <Box
                                sx={{
                                  height: "8px",
                                  width: "8px",
                                  borderRadius: "50%",
                                  backgroundColor: "grey",
                                  marginRight: "5px",
                                }}
                              ></Box>
                              N/A
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                fontSize: "11px",
                                borderRadius: "10px",
                                padding: "2px 10px",
                                backgroundColor: "#D8FFD8",
                                display: "flex",
                                alignItems: "center",
                                // justifyContent: "center",
                                width: "100px",
                                marginBottom: "2px",
                                color: "#07751A",
                              }}
                            >
                              <Box
                                sx={{
                                  height: "8px",
                                  width: "8px",
                                  borderRadius: "50%",
                                  backgroundColor: "#07751A",
                                  marginRight: "5px",
                                }}
                              ></Box>
                              {data.state}
                            </Box>
                          )}
                          <Box sx={{ fontSize: "12px" }}>
                            <b>Date:</b>{" "}
                            {data.accountActivatedDate === null
                              ? "N/A"
                              : data.accountActivatedDate.split("T")[0]}
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </>
          )}
        </Table>

        {/* <MerchantModal open={open} onClose={handleClose} data={rows} /> */}
      </TableContainer>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "end",
          gap: "20px",
          fontSize: "13px",
          backgroundColor: "#FCDFAE1A",
          color: "#6E6893",
          padding: "10px 0",
          borderRadius: " 0 0 10px 10px",
        }}
      >
        <Typography sx={{ fontWeight: 600, fontSize: "13px" }}>
          Rows per page: {itemsPerPage}
        </Typography>
        <label htmlFor="pageDropdown">
          {currentPage + 1} of {TotalPages}
        </label>
        <Button disabled={currentPage === 0} onClick={goToPreviousPage}>
          <NavigateBeforeIcon />
        </Button>
        <Button
          disabled={currentPage === TotalPages - 1}
          onClick={goToNextPage}
        >
          <NavigateNextIcon />
        </Button>
      </Box>
    </Box>
  );
};

export default MerchantOverviewTable;
