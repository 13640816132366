import axios from "axios";
import { Endpoints, TwoFAEndpoints } from "./EndPoints";
import { useHistory } from "react-router";

const token = localStorage.getItem("token");
// Set the default "Authorization" header for all get requests
axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
  "token"
)}`;

// Set the default "Authorization" header for all POST requests

export const SignIn = async (credentials: any) => {
  const originalAuthorizationHeader =
    axios.defaults.headers.common["Authorization"];
  delete axios.defaults.headers.common["Authorization"];
  try {
    const response = await axios.post(Endpoints.Auth.signin, credentials);
    const userToken = response.data.datas.token;
    localStorage.setItem("token", userToken);
    localStorage.setItem("userEmail", credentials.username);
    if (localStorage.getItem("token")) {
      return response.data;
    }
  } catch (error) {
    throw new Error("Sign-in failed");
  } finally {
    // Restore the "Authorization" header in the defaults after the request is made
    axios.defaults.headers.common["Authorization"] =
      originalAuthorizationHeader;
  }
};
export const SignOut = () => {
  window.location.href = "/";
  localStorage.removeItem("token");
  localStorage.removeItem("isUsing2FA");
  localStorage.removeItem("userEmail");
  localStorage.removeItem("isAuthenticated");
};

export const GetCustomerList = async () => {
  // try {
  //   const response = await axios.get(Endpoints.Customer.customerList, {
  //     headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  //   });
  //   return response.data;
  // } catch (error) {
  //   throw new Error("Customer List failed");
  // }
};

export const checkAuthentication = () => {
  if (localStorage.getItem("token")) {
    return true;
  }
  return true;
};

export const GetActiveRequestList = async () => {
  try {
    const response = await axios.get(Endpoints.Customer.activateRequest, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    return response.data;
  } catch (error) {
    throw new Error("Customer List failed");
  }
};

export const GetRegisteredCustomers = async () => {
  try {
    const response = await axios.get(Endpoints.Customer.registeredCustomers, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    return response.data;
  } catch (error) {
    throw new Error("Registered Customer failed");
  }
};

export const ActivateCustomer = async (credentials: any) => {
  try {
    const response = await axios.post(
      Endpoints.Customer.activateCustomers,
      credentials,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Activation failed");
  }
};

export const ActivateDocument = async (credentials: any) => {
  try {
    const response = await axios.post(
      Endpoints.Document.Validate,
      credentials,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Activation failed");
  }
};

export const GetRetrieveDetail = async (merchantID: any) => {
  try {
    const response = await axios.get(
      `${Endpoints.Customer.retrieveDetail}?merchandId=${merchantID}`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Registered Customer failed");
  }
};

export const GetTotalRequestPaymentAmount = async () => {
  try {
    const response = await axios.get(
      Endpoints.Finance.TotalRequestPaymentAmount,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Error");
  }
};
export const GetTotalRefundAmount = async () => {
  try {
    const response = await axios.get(Endpoints.Finance.TotalRefundAmount, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    return response.data;
  } catch (error) {
    throw new Error("Error");
  }
};

export const GetTotalDepositAmount = async () => {
  try {
    const response = await axios.get(Endpoints.Finance.TotalDepositAmount, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    return response.data;
  } catch (error) {
    throw new Error("Error");
  }
};

export const GetTotalChargeBackAmount = async () => {
  try {
    const response = await axios.get(Endpoints.Finance.TotalChargebackAmount, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    return response.data;
  } catch (error) {
    throw new Error("Error");
  }
};
export const GetRequestPaymentList = async () => {
  try {
    const response = await axios.get(Endpoints.Finance.RequestPaymentList, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    return response.data;
  } catch (error) {
    throw new Error("Error");
  }
};
export const GetRefundPaymentList = async () => {
  try {
    const response = await axios.get(Endpoints.Finance.RefundPaymenttList, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    return response.data;
  } catch (error) {
    throw new Error("Error");
  }
};
export const GetDepositPaymentList = async () => {
  try {
    const response = await axios.get(Endpoints.Finance.DepositPaymenttList, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    return response.data;
  } catch (error) {
    throw new Error("Error");
  }
};

export const GetTopupTransactionStatistique = async () => {
  try {
    const response = await axios.get(
      Endpoints.Transfer.TopupTransactionStatistique,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Error");
  }
};

export const GetTopupTransaction = async () => {
  try {
    const response = await axios.get(Endpoints.Transfer.TopupTransaction, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    return response.data;
  } catch (error) {
    throw new Error("Error");
  }
};

export const GetTopupTransactionRecently = async () => {
  try {
    const response = await axios.get(
      Endpoints.Transfer.TopupTransactionRecently,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Error");
  }
};
export const ValidateTopup = async ({
  credentials,
  authenticationCode,
}: any) => {
  try {
    const response = await axios.post(
      `${Endpoints.Transfer.ValidateTopup}?code=${authenticationCode}`,
      // `${Endpoints.Transfer.ValidateTopup}?topId=${credentials.topId}&status=APPROVED`,
      // "http://74.208.84.251:9016/api/v1/transfer/validTopUp?topId=18&status=APPROVED",
      credentials,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Activation failed");
  }
};
export const GetTopupTransactionApproved = async () => {
  try {
    const response = await axios.get(Endpoints.Transfer.ApprovedTopup, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    return response.data;
  } catch (error) {
    throw new Error("Error");
  }
};
export const GetTopupTransactionRejected = async () => {
  try {
    const response = await axios.get(Endpoints.Transfer.RejectedTopup, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    return response.data;
  } catch (error) {
    throw new Error("Error");
  }
};

// 2FA
export const Disable2FA = async (credentials: any) => {
  try {
    const response = await axios.post(
      `${TwoFAEndpoints.Disable2FA}/${credentials.username}/${credentials.vcode}`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Activation failed");
  }
};

export const Enable2FA = async (credentials: any) => {
  try {
    const response = await axios.post(
      `${TwoFAEndpoints.Enable2FA}/${credentials.username}`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Activation failed");
  }
};

export const Verify2FA = async (credentials: any) => {
  try {
    const response = await axios.post(
      `${TwoFAEndpoints.Verify2FA}`,
      credentials,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Activation failed");
  }
};
export const Validate2FA = async (credentials: any) => {
  try {
    const response = await axios.post(
      `${TwoFAEndpoints.Validate2FA}/${credentials.username}/${credentials.verificationCode}`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Activation failed");
  }
};
// REDUX REPLACED
// export const GetAccountBalance = async ({ dateDeb, dateFin }: any) => {
//   try {
//     const response = await axios.get(
//       `${Endpoints.Finance.AccountBalance}?dateDeb=${dateDeb}&dateFin=${dateFin}`,
//       {
//         headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
//       }
//     );
//     return response.data;
//   } catch (error) {
//     throw new Error("Error");
//   }
// };
// export const GetTransactionValue = async ({ dateDeb, dateFin }: any) => {
//   try {
//     const response = await axios.get(
//       `${Endpoints.Finance.TransactionValue}?dateDeb=${dateDeb}&dateFin=${dateFin}`,
//       {
//         headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
//       }
//     );
//     return response.data;
//   } catch (error) {
//     throw new Error("Error");
//   }
// };
// export const GetTransactionCount = async ({ dateDeb, dateFin }: any) => {
//   try {
//     const response = await axios.get(
//       `${Endpoints.Finance.TransactionCount}?dateDeb=${dateDeb}&dateFin=${dateFin}`,
//       {
//         headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
//       }
//     );
//     return response.data;
//   } catch (error) {
//     throw new Error("Error");
//   }
// };
export const GetAllRates = async () => {
  try {
    const response = await axios.get(Endpoints.ManageRates.AllRate, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    return response.data;
  } catch (error) {
    throw new Error("Error");
  }
};

export const GetMerchantList = async (credential: any) => {
  let url;
  if (credential.state) {
    url = `${Endpoints.User.AllAccounts}?state=${credential.state}&page=${credential.page}&size=${credential.size}`;
  } else {
    url = `${Endpoints.User.AllAccounts}?page=${credential.page}&size=${credential.size}`;
  }
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data.message) {
      return error.response.data.message;
    } else {
      return error.message;
    }
  }
};
