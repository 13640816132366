import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  CardMedia,
  Skeleton,
  CircularProgress,
} from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  TogoIcon,
  BeninIcon,
  CotedivoireIcon,
  InactiveIcon,
  SuspendedIcon,
} from "../../../../common/assets/icons"; 
import PayoutTable from "./PayoutTable";
import {
  GetTopupTransactionStatistique,
  GetTopupTransaction,
  GetTopupTransactionRecently,
  GetTopupTransactionApproved,
  GetTopupTransactionRejected
} from "../../../../api/Data";
// Tab Functions
function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PayoutOverview = () => {
  const [value, setValue] = useState(0);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  const [topupTransactionRecent, setTopupTransactionRecent] = useState<any>([]);
  const [topupTransactionApproved, setTopupTransactionApproved] = useState<any>([]);
  const [topupTransactionRejected, setTopupTransactionRejected] = useState<any>([]);
  const [totalTopUpRequest, setTotalTopUpRequest] = useState<any>("");
  const [totalAmountTopUpRequest, setTotalAmountTopUpRequest] =
    useState<any>("");
  const [totalAmountSucessTopUp, setTotalAmountSucessTopUp] = useState<any>("");

  useEffect(() => {
    const fetchTopupTransactionStatistique = async () => {
      try {
        const response = await GetTopupTransactionStatistique();
        setTotalTopUpRequest(response.datas.totalTopUpRequest);
        setTotalAmountSucessTopUp(response.datas.totalAmountSucessTopUp);
        setTotalAmountTopUpRequest(response.datas.totalAmountTopUpRequest);
        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        setErrorMessage(true);
        setLoading(false); // Set loading to false in case of an error
      }
    };
    const fetchTopupTransaction = async () => {
      try {
        const response = await GetTopupTransaction();
        setTableData(response.datas);
        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        setErrorMessage(true);
        setLoading(false); // Set loading to false in case of an error
      }
    };
    const fetchTopupTransactionRecently = async () => {
      try {
        const response = await GetTopupTransactionRecently();
        setTopupTransactionRecent(response.datas);
      } catch (error) {
        setErrorMessage(true);
      }
    };
    const fetchTopupTransactionApproved = async () => {
      try {
        const response = await GetTopupTransactionApproved();
        setTopupTransactionApproved(response.datas);
      } catch (error) {
        setErrorMessage(true);
      }
    };
    const fetchTopupTransactionRejected = async () => {
      try {
        const response = await GetTopupTransactionRejected();
        setTopupTransactionRejected(response.datas);
      } catch (error) {
        setErrorMessage(true);
      }
    };
    fetchTopupTransaction();
    fetchTopupTransactionStatistique();
    fetchTopupTransactionRecently();
    fetchTopupTransactionApproved();
    fetchTopupTransactionRejected();
  }, []);

  const handleTabChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  // const filteredTransactions = topupTransactionRecent.filter(
  //   (transaction: any) => transaction.status !== "NOVALID"
  // );

  return (
    <div>
      {/* Heading */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "start",
          marginBottom: "20px",
        }}
      >
        <Typography
          sx={{ fontSize: "24px", color: "#45464E", marginBottom: "-5px" }}
        >
          Payout
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            color: "#B2B2B2",
            fontWeight: 500,
            marginTop: "0",
          }}
        >
          Manage your payout activities here
        </Typography>
      </Box>
      {/* Four Cards */}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            sm: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
            lg: "repeat(4, 1fr)",
          },
          gridGap: "1rem",
          margin: "0",
        }}
      >
        {/* Total Payout Request */}
        {loading ? (
          <Skeleton
            variant="rectangular"
            sx={{
              height: "140px",
              backgroundColor: "#FFFFFF",
              border: "solid 1px #E0E0E0",
              borderRadius: "8px",
            }}
            animation="wave"
          />
        ) : errorMessage ? (
          <>Error Loading Component</>
        ) : (
          <Box
            sx={{
              height: "100%",
              backgroundColor: "#FFFFFF",
              border: "solid 1px #E0E0E0",
              borderRadius: "8px",
              padding: "25px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  color: "#797D8C",
                  fontSize: "12px",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                <b>Total</b> <br />
                Payout Request
              </Typography>
              <CardMedia
                component="img"
                height="20px"
                image={InactiveIcon}
                alt="Icon"
                sx={{
                  objectFit: "contain",
                  marginLeft: "10px",
                  width: "20px",
                }}
              />
            </Box>
            <Typography sx={{ fontSize: "24px", fontWeight: "600" }}>
              {loading ? <CircularProgress /> : Math.floor(totalTopUpRequest)}
            </Typography>
          </Box>
        )}
        {/*Total Amout Payout Request */}
        {loading ? (
          <Skeleton
            variant="rectangular"
            sx={{
              height: "140px",
              backgroundColor: "#FFFFFF",
              border: "solid 1px #E0E0E0",
              borderRadius: "8px",
            }}
            animation="wave"
          />
        ) : (
          <Box
            sx={{
              height: "100%",
              backgroundColor: "#FFFFFF",
              border: "solid 1px #E0E0E0",
              borderRadius: "8px",
              padding: "25px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  color: "#797D8C",
                  fontSize: "12px",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                <b>Total</b> <br />
                Amout Payout Request
              </Typography>
              <CardMedia
                component="img"
                height="20px"
                image={SuspendedIcon}
                alt="Icon"
                sx={{
                  objectFit: "contain",
                  marginLeft: "10px",
                  width: "20px",
                }}
              />
            </Box>
            <Typography sx={{ fontSize: "24px", fontWeight: "600" }}>
              {loading ? <CircularProgress /> : totalAmountTopUpRequest}
            </Typography>
          </Box>
        )}
        {/* Successful Payout Request */}
        {loading ? (
          <Skeleton
            variant="rectangular"
            sx={{
              height: "140px",
              backgroundColor: "#FFFFFF",
              border: "solid 1px #E0E0E0",
              borderRadius: "8px",
            }}
            animation="wave"
          />
        ) : (
          <Box
            sx={{
              height: "100%",
              backgroundColor: "#FFFFFF",
              border: "solid 1px #E0E0E0",
              borderRadius: "8px",
              padding: "25px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  color: "#797D8C",
                  fontSize: "12px",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                <b>Successful</b> <br />
                Payout Request
              </Typography>
              <CardMedia
                component="img"
                height="20px"
                image={SuspendedIcon}
                alt="Icon"
                sx={{
                  objectFit: "contain",
                  marginLeft: "10px",
                  width: "20px",
                }}
              />
            </Box>
            <Typography sx={{ fontSize: "24px", fontWeight: "600" }}>
              {loading ? <CircularProgress /> : totalAmountSucessTopUp}
            </Typography>
          </Box>
        )}
        {loading ? (
          <Skeleton
            variant="rectangular"
            sx={{
              height: "140px",
              backgroundColor: "#FFFFFF",
              border: "solid 1px #E0E0E0",
              borderRadius: "8px",
            }}
            animation="wave"
          />
        ) : (
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              border: "solid 1px #E0E0E0",
              borderRadius: "8px",
              padding: "20px 25px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography
              sx={{
                color: "#797D8C",
                fontSize: "12px",
                fontWeight: 500,
                marginBottom: "4px",
              }}
            >
              Requests Per Country
            </Typography>
            <Box
              sx={{
                display: "grid",
                gridTemplateRows: "1fr 1fr 1fr",
                gridGap: "5px",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <CardMedia
                  component="img"
                  height="25px"
                  image={BeninIcon}
                  alt="Icon"
                  sx={{
                    objectFit: "contain",
                    marginRight: "15px",
                    width: "20px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  N/A
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <CardMedia
                  component="img"
                  height="25px"
                  image={TogoIcon}
                  alt="Icon"
                  sx={{
                    objectFit: "contain",
                    marginRight: "15px",
                    width: "20px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  N/A
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <CardMedia
                  component="img"
                  height="25px"
                  image={CotedivoireIcon}
                  alt="Icon"
                  sx={{
                    objectFit: "contain",
                    marginRight: "15px",
                    width: "20px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  N/A
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      {/* Tabs Head*/}
      <Box sx={{ borderBottom: 0, marginBottom: "10px", marginTop: "30px" }}>
        <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label="basic tabs example"
          sx={{
            borderBottom: "none", // Remove the default bottom border
          }}
        >
          <Tab
            label="Topup Request"
            className={value === 0 ? "activeTab" : ""}
            sx={{
              border: value === 0 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 0 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(0)}
          />
          <Tab
            label="Recent Topup"
            className={value === 1 ? "activeTab" : ""}
            sx={{
              border: value === 1 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 1 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(1)}
          />
                   <Tab
            label="Approved Topup"
            className={value === 2 ? "activeTab" : ""}
            sx={{
              border: value === 2 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 2 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(2)}
          />
                   <Tab
            label="Rejected Topup"
            className={value === 3 ? "activeTab" : ""}
            sx={{
              border: value === 3 ? "#F59E0B 1px solid" : "#EFF1F6  1px solid",
              borderRadius: "8px",
              fontSize: "14px",
              lineHeight: "16px",
              textTransform: "capitalize",
              marginRight: "12px",
              backgroundColor: value === 3 ? "#FCDFAE" : "inherit",
            }}
            {...a11yProps(3)}
          />
        </Tabs>
      </Box>
      <Box sx={{ width: "100%" }} aria-label="scrollable auto tabs example">
        {/* TOPUP REQUEST */}
        <TabPanel value={value} index={0}>
          {loading ? (
            <Box sx={{}}>
              <Skeleton
                variant="rectangular"
                sx={{
                  height: "140px",
                  backgroundColor: "#FFFFFF",
                  border: "solid 1px #E0E0E0",
                  borderRadius: "8px",
                }}
                animation="wave"
              />
            </Box>
          ) : (
            <PayoutTable data={tableData} title="Topup Request List" />
          )}
        </TabPanel>
        {/* RECENT TOPUP */}
        <TabPanel value={value} index={1}>
          {loading ? (
            <Box sx={{}}>
              <Skeleton
                variant="rectangular"
                sx={{
                  height: "140px",
                  backgroundColor: "#FFFFFF",
                  border: "solid 1px #E0E0E0",
                  borderRadius: "8px",
                }}
                animation="wave"
              />
            </Box>
          ) : (
            <PayoutTable data={topupTransactionRecent} title="Recent Topup List" />
          )}
        </TabPanel>
                {/* RECENTLY APPROVED */}
                <TabPanel value={value} index={2}>
          {loading ? (
            <Box sx={{}}>
              <Skeleton
                variant="rectangular"
                sx={{
                  height: "140px",
                  backgroundColor: "#FFFFFF",
                  border: "solid 1px #E0E0E0",
                  borderRadius: "8px",
                }}
                animation="wave"
              />
            </Box>
          ) : (
            <PayoutTable data={topupTransactionApproved} title="Approved Topup List" />
          )}
        </TabPanel>
                {/* RECENTLY REJECTED */}
                <TabPanel value={value} index={3}>
          {loading ? (
            <Box sx={{}}>
              <Skeleton
                variant="rectangular"
                sx={{
                  height: "140px",
                  backgroundColor: "#FFFFFF",
                  border: "solid 1px #E0E0E0",
                  borderRadius: "8px",
                }}
                animation="wave"
              />
            </Box>
          ) : (
            <PayoutTable data={topupTransactionRejected} title="Rejected Topup List" />
          )}
        </TabPanel>
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            border: "solid 1px #E0E0E0",
            borderRadius: "8px",
            marginTop: "1rem",
          }}
        ></Box>
      </Box>
    </div>
  );
};

export default PayoutOverview;
