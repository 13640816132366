const config = {
  development: {
    baseURL: "http://74.208.84.251:9016",
  },
  testing: {
    baseURL: "http://74.208.84.251:9016",
  },
  production: {
    baseURL: "https://b-admin.qosic.net",
  },
};
const targetURL = "https://admin.qosic.net/";

const getBaseUrl = () => {
  if (
    process.env.NODE_ENV === "production" &&
    window.location.href.includes(targetURL)
  ) {
    return config.production.baseURL;
  } else if (process.env.NODE_ENV === "development") {
    return config.development.baseURL;
  } else {
    // Default to development environment
    return config.testing.baseURL;
  }
};

export const baseUrl = getBaseUrl();
export const apiVersion = "/api/v1";

export const Endpoints = {
  Auth: {
    signin: baseUrl + apiVersion + "/auth/signin",
    signOut: baseUrl + apiVersion + "/auth/logout",
    Disable2FA: baseUrl + apiVersion + "/auth/disabled-2fa",
    Enable2FA: baseUrl + apiVersion + "/auth/enabled-2fa",
    Verify2FA: baseUrl + apiVersion + "/auth/enabled-2fa/verify-2fa",
    Validate2FA: baseUrl + apiVersion + "/auth/valid-2fa",
  },
  Overview: {
    dashboard: baseUrl + apiVersion + "/dashboard",
    dashboardOverview: baseUrl + apiVersion + "/dashboard/admin-dashboard",
    TransactionFifthSuccess:
      baseUrl + apiVersion + "/dashboard/transactionFifthSuccess",
    offerUserList: baseUrl + apiVersion + "/dashboard/offerUserList",
    momoPayments: baseUrl + apiVersion + "/dashboard/momoPayments",
    momoLast7Transfer: baseUrl + apiVersion + "/dashboard/momoLast7Transfer",
  },
  Customer: {
    customerList: baseUrl + apiVersion + "/customer/customerList",
    registeredCustomers: baseUrl + apiVersion + "/customer/registredCustomers",
    activateCustomers: baseUrl + apiVersion + "/customer/activeCustomers",
    activateRequest: baseUrl + apiVersion + "/customer/activationRequest",
    retrieveDetail: baseUrl + apiVersion + "/customer/retrieve-detail",
    CustomerInformation: baseUrl + apiVersion + "/customer/customerInformation",
  },
  Document: {
    Validate: baseUrl + apiVersion + "/documents/valide",
    Upload: baseUrl + apiVersion + "/documents/upload",
    View: baseUrl + apiVersion + "/documents/view",
    List: baseUrl + apiVersion + "/documents/list",
  },
  Finance: {
    TotalRequestPaymentAmount:
      baseUrl + apiVersion + "/finance/getTotalRequestPaymentAmount",
    TotalRefundAmount: baseUrl + apiVersion + "/finance/getTotalRefundAmount",
    TotalDepositAmount: baseUrl + apiVersion + "/finance/getTotalDepositAmount",
    TotalDepositAmountMoov:
      baseUrl + apiVersion + "/finance/getTotalDepositAmountMoov",
    TotalChargebackAmount:
      baseUrl + apiVersion + "/finance/getTotalChargebackAmount",
    TotalAvailableAmount:
      baseUrl + apiVersion + "/finance/getTotalAvailableAmount",
    TotalAvailableAmountSp:
      baseUrl + apiVersion + "/finance/getTotalAvailableAmountSp",
    TotalAvailableAmountMoov:
      baseUrl + apiVersion + "/finance/getTotalAvailableAmountMoov",
    RequestPaymentAmountSp:
      baseUrl + apiVersion + "/finance/getRequestPaymentAmountSp",
    RequestPaymentAmountMoov:
      baseUrl + apiVersion + "/finance/getRequestPaymentAmountMoov",
    RefundAmountSp: baseUrl + apiVersion + "/finance/getRefundAmountSp",
    RefundAmountMoov: baseUrl + apiVersion + "/finance/getRefundAmountMoov",
    DepositAmountSp: baseUrl + apiVersion + "/finance/getDepositAmountSp",
    ChargebackAmountSp: baseUrl + apiVersion + "/finance/getChargebackAmountSp",
    ChargebackAmountMoov:
      baseUrl + apiVersion + "/finance/getChargebackAmountMoov",
    RequestPaymentList: baseUrl + apiVersion + "/finance/getRequestPayment",
    RefundPaymenttList: baseUrl + apiVersion + "/finance/getRefundPayment",
    DepositPaymenttList: baseUrl + apiVersion + "/finance/getDepositPayment",
    AccountBalance: baseUrl + apiVersion + "/finance/getAccountBalance",
    TransactionValue: baseUrl + apiVersion + "/finance/getTransactionValue",
    TransactionCount: baseUrl + apiVersion + "/finance/getTransactionCount",
    TopTenTransaction: baseUrl + apiVersion + "/finance/getTopTenTransaction",
  },
  FinanceReport: {
    MerchantFinanceReport:
      baseUrl + "/api/finance-report/merchant-finance-report",
    GeneralFinanceReport:
      baseUrl + "/api/finance-report/general-finance-report",
  },
  ManageRates: {
    AddRate: baseUrl + apiVersion + "/rate/addRate",
    AllRate: baseUrl + apiVersion + "/rate/allRate",
    UpdateRate: baseUrl + apiVersion + "/rate/updateRate",
    AddMerchantRate: baseUrl + apiVersion + "/rate/addMerchanRate",
    AllMerchantRate: baseUrl + apiVersion + "/rate/allMerchanRate",
    UpdateMerchantRate: baseUrl + apiVersion + "/rate/updateMerchanRate",
    MerchantInformation: baseUrl + apiVersion + "/rate/MerchanRatebyMerchan",
  },
  Transfer: {
    TopupTransactionStatistique:
      baseUrl + apiVersion + "/transfer/topupTransactionStatistique",
    TopupTransaction: baseUrl + apiVersion + "/transfer/topupTransaction",
    TopupTransactionRecently:
      baseUrl + apiVersion + "/transfer/topupTransactionRecently",
    ValidateTopup: baseUrl + apiVersion + "/transfer/validTopUp",
    ApprovedTopup: baseUrl + apiVersion + "/transfer/topupTransactionApproved",
    RejectedTopup: baseUrl + apiVersion + "/transfer/topupTransactionRejected",
  },
  Transaction: {
    AllTransactionLog: baseUrl + "/api/transactions/all-transaction-history",
    TransactionLog: baseUrl + "/api/transactions/transaction-log",
    TransactionLogToEmail:
      baseUrl + "/api/transactions/transaction-log-to-email",
    MerchantTransactionLog:
      baseUrl + "/api/transactions/merchant-transaction-log",
    MerchantTransactionLogToEmail:
      baseUrl + "/api/transactions/merchant-transaction-log-to-email",
    Transaction: baseUrl + "/api/transactions/search-transaction",
    UpdateTransactionStatus:
      baseUrl + "/api/transactions/update-transaction-status",
    TransactionLogDownload:
      baseUrl + "/api/transactions/transaction-log-without-pagination-v2",
    MerchantTransactionLogDownload:
      baseUrl +
      "/api/transactions/merchant-transaction-log-without-pagination-v2",
  },
  User: {
    AllAccounts: baseUrl + "/api/v1/user/all-accounts",
    MerchantAnalystics: baseUrl + "/api/v1/user/merchant-analytics",
  },
  Payment: {
    PaymentAnalystics: baseUrl + "/api/payment/analytics",
  },
};

export const TwoFAEndpoints = {
  Disable2FA: baseUrl + apiVersion + "/auth/disabled-2fa",
  Enable2FA: baseUrl + apiVersion + "/auth/enabled-2fa",
  Verify2FA: baseUrl + apiVersion + "/auth/enabled-2fa/verify-2fa",
  Validate2FA: baseUrl + apiVersion + "/auth/valid-2fa",
};
