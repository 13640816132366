export const ROUTES = {
  VERIFY2FA: "/verify2fa",
  DASHBOARD: "/dashboard",
  MERCHANT_OVERVIEW: "/merchant-overview",
  MERCHANT_LIST: "/merchant-list",
  ACTIVATION_REQUEST: "/compliance",
  FINANCE_OVERVIEW: "/payment-overview",
  COMING_SOON: "/comingsoon",
  SUMMARY: "/summary",
  MERCHANT_DETAILS: "/merchant-details",
  TOPUP: "/topup",
  PAYOUT: "/payout",
  REVENUE: "/revenue",
  MANAGE_RATES: "/manage-rates",
  FINANCE_REPORT: "/payment-report",
  TRANSACTION_LOG: "/transaction-log",
  SETTINGS: "/settings",
};
