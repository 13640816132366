import { Box, Typography, Divider } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { TopMerchantProp } from "../../../../types/FinanceData";

const TopMerchants = ({ data, tableTitle }: TopMerchantProp) => {
  const itemsPerPage = 10; // Set the number of items per page according to your requirement
console.log(data)
  return (
    <Box sx={{ boxShadow: 1 }}>
      <Box
        sx={{
          padding: "15px",
          display: "flex",
          gap: "1rem",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Typography
          sx={{ textAlign: "left", fontSize: "25px", fontWeight: 500 }}
        >
          {tableTitle}  {data === undefined || data.length === 0 ? "(0)" : `(${data.length})`}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: { xs: "100%", md: "max-content" },
          }}
        ></Box>
      </Box>
      <Divider />
      <TableContainer
        component={Paper}
        sx={{
          width: "100%",
          overflowX: "auto",
          borderRadius: "0",
          border: "none",
          boxShadow: 0,
        }}
      >
        <Table sx={{ padding: 0, margin: 0 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#FCDFAE1A", textTransform: "" }}>
              <TableCell sx={{ fontWeight: 600, fontSize: "14px" }}>
                Company Name
              </TableCell>
              <TableCell sx={{ fontWeight: 600, fontSize: "14px" }}>
                Transaction Count
              </TableCell>
              <TableCell
                align="center"
                sx={{ fontWeight: 600, fontSize: "14px" }}
              >
                Transaction Value
              </TableCell>
              <TableCell sx={{ fontWeight: 600, fontSize: "14px" }}>
                Transaction Percentage
              </TableCell>
              <TableCell
                align="right"
                sx={{ fontWeight: 600, fontSize: "14px" }}
              >
                Available Balance
              </TableCell>
              <TableCell
                align="right"
                sx={{ fontWeight: 600, fontSize: "14px" }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          {data === undefined || data.length === 0 ? (
            <TableBody>
              <TableRow>
                <TableCell>No Data</TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {data.map((data, index: number) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {" "}
                  <TableCell component="th" scope="row">
                    {data.companyName === null ? "N/A" : data.companyName}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {data.transactionCount === null
                      ? "N/A"
                      : data.transactionCount}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {data.transactionValue === null
                      ? "N/A"
                      : data.transactionValue}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {data.transactionPercentage === null
                      ? "N/A"
                      : data.transactionPercentage}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {data.availableBalance === null
                      ? "N/A"
                      : data.availableBalance}
                  </TableCell>
                  <TableCell align="right">
                    {/* <Link 
                        to={`${ROUTES.MERCHANT_DETAILS}/${data.merchandId}`}
                      > */}
                    <MoreVertOutlinedIcon sx={{ cursor: "pointer" }} />
                    {/* </Link> */}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "end",
          gap: "20px",
          fontSize: "13px",
          backgroundColor: "#FCDFAE1A",
          color: "#6E6893",
          padding: "15px 20px",
          borderRadius: " 0 0 10px 10px",
        }}
      >
        <Typography sx={{ fontWeight: 600, fontSize: "13px" }}>
          Rows per page: {itemsPerPage}
        </Typography>
      </Box>
    </Box>
  );
};

export default TopMerchants;
